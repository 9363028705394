<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import VueRssFeed from "@/components/rssfeed";
import simplebar from "simplebar-vue";
import { mapGetters, mapActions, mapState } from 'vuex';



export default {
  components: {
    Layout,
    PageHeader,
    VueRssFeed,
    simplebar
  },
   props: {
    id: {
      type: String,
      default: '',
    }
  },
  data() {
    
    return {
      initialized: false,
      paperTradeStarted: false,
      replacementPositionsStarted: false,
      feed:{
        feedUrl: null,
        name: "",
        limit: 10,
      },
      editingProfile: null,
      title: 'Tax Loss Harvest',
      expanded: {

      },
      breadcrumbs: [
        
      ],
      taxLoss: null
    };
  },
  created() {
    if(process.env.VUE_APP_TYPE === 'CRYPTO') {
      this.feed.feedUrl = process.env.VUE_APP_BASE_URL + "/api/rss/cointelegraph";
    } else {
      this.feed.feedUrl = process.env.VUE_APP_BASE_URL + "/api/rss/yahoo";
    }
    this.init();
  },
  computed: {
    ...mapState('signalr', {
      connectionId:'connectionId'
    }),
    isBusy () {
      return this.loading ;
    },
    ...mapGetters('auth', {
      token: 'token'
    }),
    ...mapGetters('portfolios', {
      items: 'items',
      loading: 'loading'
    }),
    portfolio () {
      return this.items.find(x => x.id === this.id) || {};
    },
    taxLossHarvestExecutionData() {
      return (this.taxLoss?.details ?? []).map(
        x => ({
          productName: x.name,
          size: x.size
        }));
    }
  },
  watch: {
    connectionId(newValue, oldValue) {
      if(newValue && (newValue !== oldValue)) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions('portfolios', {
      loadPortfolio: 'loadUserPortfolio',
      taxLossHarvestReplacementPositions: 'taxLossHarvestReplacementPositions',
      taxLossHarvestPaperTrade: 'taxLossHarvestPaperTrade'
    }),
    async onPaperTrade() {
      this.paperTradeStarted = true;
      const payload = { 
        portfolioName: this.portfolio.name,
        items: this.taxLoss.details.map(x => {
          return {
            name: x.name,
            size: x.size,
            direction: x.direction
          }
        })
      };
      await this.taxLossHarvestPaperTrade(payload);
      this.paperTradeStarted = false;
    },
    async onReplacementPositions() {
      this.replacementPositionsStarted = true;
      const payload = { 
        portfolioName: this.portfolio.name,
        items: this.taxLoss.details.map(x => {
          return {
            
            name: x.name
          }
        })
      };
      await this.taxLossHarvestReplacementPositions(payload);
      this.replacementPositionsStarted = false;
    },
    expand(index) {
      const o = {...this.expanded};
      o[index] = o[index] ? !o[index] : true;
      this.expanded = o;
    },
    async init() {
       console.log('init', this.connectionId);
      if(this.connectionId) {
        this.initialized = true;
        await this.loadPortfolio({ id: this.id});
        const o = JSON.parse(JSON.stringify(this.portfolio.data.taxLoss));
        if(o && o.details) {
          o.details.forEach(rec => {
            if(rec.direction === 'Sell') {
              rec.size = rec.size * -1;
            }
          })
        }
        this.taxLoss = o;
      } else {
        console.log('clientId is null. waiting...');
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"  :is-busy="isBusy"/>
   
    <div class="p-3" v-if="loading">
      <i class="fa fa-spin fa-spinner"></i> Loading...
    </div>
    <div class="row" v-if="taxLoss">
      <div class="col-12">
        <div class="email-leftbar p-0" >
          <div class="card p-0 cardc8">
            <div class="card-content m-3">
              <div class="card-header bg-transparent d-flex justify-content-between"><h5>Summary</h5></div>
              <div v-if="taxLoss">
                <table class="table  table-sm table-borderless">
                  <tbody>
                      <tr>
                        <td class="col-6  p-2"></td>
                        <td class="col-3 text-right p-2">Tax Loss</td>
                        <td class="col-3 text-right p-2">Restricted Until</td>
                      </tr>
                    
                    </tbody>
                    <tbody>
                      <template  v-for="(rec, $index) in taxLoss.summary.taxLossSummaryItems">
                        <template>
                          <tr :key="$index.toString() + 'r'">
                            <td class="col-6 font-bold  px-2"> <b>{{rec.name}}</b></td>
                            <td class="col-3  font-bold text-right  px-2"> <b>{{rec.amount.toLocaleString('en-US')}}</b></td>
                            <td class="col-3  font-bold text-right  px-2">
                              <b>
                              <span v-if="!rec.restrictionDate" class="text-muted">None</span>
                              <span v-if="rec.restrictionDate" class="text-muted"> {{rec.restrictionDate | moment('L')}}</span>
                              </b>
                            </td>
                          </tr>
                          <tr v-for="(trade, $subindex) in rec.trades" :key=" $index + $subindex.toString() + 's'">
                            <td colspan="100" class="text-right  px-2">
                            <span class="mb-2 text-muted small">
                              {{trade.dateTime | moment('L')}} {{trade.direction}} {{trade.size}} at {{trade.price}} 
                            </span>
                            </td>
                          </tr>
                          <tr :key="$index.toString() + 'r2'">
                            <td class="border-bottom" colspan="1000"></td>
                          </tr>
                        </template>
                      </template>
                      
                    </tbody>
                  </table>
              </div>
            </div>
          </div>
          <div class="card p-0 cardc8 mt-2 rss-feed">
            <div class="card-content  p-0"> 
                <div class="mt-3 ml-3 mr-3 card-header bg-transparent d-flex justify-content-between"><h5>News</h5></div>
                <simplebar class="p-2" style="max-height: 600px;">
                  <VueRssFeed :feedUrl="feed.feedUrl" :name="feed.name" :limit="feed.limit"/>
                </simplebar>
              
              </div>
          </div>
        </div>
        <div class="email-rightbar mb-3 p-0">
          <div class="card p-0 cardc8">
            <div class="card-content m-3">
              <div class="card-header bg-transparent d-flex justify-content-between"><h5>Details</h5></div>
              <div class="table-responsive">
                <table class="table  table-borderless nowrap">
                  <thead>
                    <tr>
                      <th style="width:30px; max-width:30px"></th>
                      <th class="col"></th>
                      <th class="col-auto">Date</th>
                      <th class="col-auto">Price</th>
                      <th class="col-auto text-nowrap">Current Position</th>
                      <th class="col-auto text-nowrap">Proposed Position</th>
                      <th class="col-auto text-nowrap">Tax Loss</th>
                      <th class="col-auto text-nowrap">Trades</th>
                      <th class="col-auto text-nowrap">Size</th>
                      <th class="col-auto text-nowrap">Price</th>
                    </tr>
                  </thead>
                  <tbody v-if="taxLoss && taxLoss.details">
                    <template v-for="(rec, $index) in taxLoss.details">
                      <tr :key="$index" :class="{'border-top border-left border-right bg-light': expanded[$index]}">
                        <td style="width:30px; max-width:30px">
                          <a  @click="expand($index)" v-if="rec.subItems.length > 0">
                            <b v-if="expanded[$index]"><i class="fa fa-chevron-up "></i></b>
                            <b v-if="!expanded[$index]"><i class="fa fa-chevron-down "></i></b>
                          </a>
                        </td>
                        <td class="col">{{rec.name}}</td>
                        <td class="col-auto text-nowrap">{{rec.date | moment('L')}}</td>
                        <td class="col-auto text-nowrap">{{(rec.price || 0).toFixed(2)}}</td>
                        <td class="col-auto text-nowrap">{{rec.currentPosition}}</td>
                        <td class="col-auto text-nowrap">{{rec.proposedPosition}}</td>
                        <td class="col-auto text-nowrap">{{(rec.taxLoss || 0).toFixed(2)}}</td>
                        <td class="col-auto text-nowrap">
                          <i class="mdi-check-bold mdi mr-2" v-if="rec.trade"></i>
                          <span>{{rec.direction}}</span>
                        </td>
                        <td class="col-auto text-nowrap">
                          <input type="number" class="form-control input-sm" style="min-width:100px" v-model.number="rec.size" />
                        </td>
                        <td class="col-auto text-nowrap">{{rec.price}}</td>
                      </tr>
                      <template v-if="expanded[$index]">
                        <tr  v-for="(subRec, $sIndex) in rec.subItems" :key="$sIndex + 'c' + $index" class="border-left border-right bg-light" :class="{'border-bottom ': $sIndex == (rec.subItems.length - 1) && expanded[$index], }">
                          <td ></td>
                          <td></td>
                          <td class="col-auto text-nowrap">{{subRec.date | moment('L')}}</td>
                          <td class="col-auto text-nowrap">{{(subRec.price || 0).toFixed(2)}}</td>
                          <td class="col-auto text-nowrap">{{subRec.currentPosition}}</td>
                          <td class="col-auto text-nowrap">{{subRec.proposedPosition}}</td>
                          <td class="col-auto text-nowrap">{{(subRec.taxLoss || 0).toFixed(2)}}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </template>
                    </template>
                    
                  </tbody>
                </table>
              </div>

              <div class="mt-3 text-right">
               
                <!-- <button class="btn btn-secondary text-nowrap mr-2" @click="onReplacementPositions()" :disabled="replacementPositionsStarted">
                  <i class="fa fa-spin fa-spinner" v-if="replacementPositionsStarted"></i>
                  Replacement Positions
                </button> -->
                <router-link class="btn btn-primary mr-2 text-nowrap" :to="{name:'execution', params: { id, taxLossHarvest: taxLossHarvestExecutionData }}">
                  Execute
                </router-link>
                <!-- <button class="btn btn-primary text-nowrap" @click="onPaperTrade()" :disabled="paperTradeStarted">
                  <i class="fa fa-spin fa-spinner" v-if="paperTradeStarted"></i>
                  Paper Trade
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>